<template>
  <div class="locations">
    <Header :pageId="pageId" :cId="cId" />
    <div class="banner">
      <div class="container">
        <div class="warpper">
          <div class="left">
            <div class="title" v-if="!pageId">
              Sell Your Car For Unbeatable Cash Through Our Buying Service
              Platform In Australia.
            </div>
            <div class="title" v-if="pageId && !cId">
              Sell Your Car For Unbeatable Cash Through Our Buying Service
              Platform In {{ pageName }}.
            </div>
            <div class="title" v-if="pageId && cId">
              Sell Your Car For Unbeatable Cash Through Our Buying Service
              Platform In {{ cPageName }}.
            </div>
            <div class="larger" v-if="pageId">
              NO MORE SECOND-GUESSING. CLOSE THE DEAL WITH US TODAY.
            </div>
            <!-- <div class="swiper">
              <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="item in swiperList"
                  :key="item.id"
                  class="stop-swiping"
                >
                  <img :src="item.path" alt="" />
                </swiper-slide>
              </swiper>
            </div> -->
            <div class="icons">
              <div class="item">
                <div class="icons-left">
                  <img src="~@/assets/images/24-Hour-Callback.png" alt="" />
                </div>
                <div class="icons-right">
                  <div class="info">SAME DAY</div>
                  <div class="info">Call Back</div>
                </div>
              </div>
              <div class="item">
                <div class="icons-left">
                  <img src="~@/assets/images/Car-Search.png" alt="" />
                </div>
                <div class="icons-right">
                  <div class="info">SAME DAY</div>
                  <div class="info">Inspection</div>
                </div>
              </div>
              <div class="item">
                <div class="icons-left">
                  <img src="~@/assets/images/Money-Bag.png" alt="" />
                </div>
                <div class="icons-right">
                  <div class="info">SAME DAY</div>
                  <div class="info">Payment</div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="lable">
              <img src="~@/assets/images/Best-Price-Badge.png" alt="" />
            </div>
            <div class="title">
              <div class="title-item">WE CAN PAY YOUR</div>
              <div class="title-item">CAR TODAY!</div>
            </div>
            <div class="desc">
              Get a real offer in minutes by fill out below quick form.
            </div>
            <div class="form">
              <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <div class="form-item">
                  <el-form-item prop="kilometres">
                    <el-input
                      v-model="ruleForm.kilometres"
                      type="text"
                      placeholder="Kilometres driven*"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <el-form-item prop="year">
                    <el-input
                      v-model="ruleForm.year"
                      type="text"
                      placeholder="Year manufactured*"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-form>
              <div class="subimit" @click="handleContinue">Continue</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-bg">
      <div class="container">
        <div class="warpper">
          <div class="title">How it Works</div>
          <div class="bg-info">
            <div class="item">
              <div class="top">
                <img src="~@/assets/images/Car-Laptop-Icon.png" alt="" />
              </div>
              <div class="item-title">Get your offer</div>
              <div class="item-desc">It takes 2 minutes.</div>
            </div>
            <div class="item">
              <div class="top">
                <img src="~@/assets/images/Car-Contract-Icon.png" alt="" />
              </div>
              <div class="item-title">Make an appointment</div>
              <div class="item-desc">
                Redeem it or compare your options for 7 days.
              </div>
            </div>
            <div class="item">
              <div class="top">
                <img src="~@/assets/images/Hand-Dollar-Icon.png" alt="" />
              </div>
              <div class="item-title">Come get paid</div>
              <div class="item-desc">
                We'll verify your offer and pay you on the spot.
              </div>
            </div>
          </div>
          <div class="bg-btn" @click="backTop">
            Sell your car fast - Get your free valuation today!
          </div>
        </div>
      </div>
    </div>
    <div class="map">
      <div class="container">
        <div class="warpper">
          <div class="wpb_wrapper">
            <div class="map-info">
              <img src="~@/assets/images/map.webp" alt="" v-if="!pageId" />
              <img src="~@/assets/images/page1.png" alt="" v-if="pageId == 1" />
              <img src="~@/assets/images/page2.png" alt="" v-if="pageId == 2" />
              <img src="~@/assets/images/page3.png" alt="" v-if="pageId == 3" />
              <img src="~@/assets/images/page4.png" alt="" v-if="pageId == 4" />
              <img src="~@/assets/images/page5.png" alt="" v-if="pageId == 5" />
              <img src="~@/assets/images/page6.png" alt="" v-if="pageId == 6" />
              <img src="~@/assets/images/page7.png" alt="" v-if="pageId == 7" />
              <img src="~@/assets/images/page8.png" alt="" v-if="pageId == 8" />
            </div>
          </div>
          <div class="right" v-if="!pageId">
            <div class="map-title">
              Australia’s most trusted car buying service
            </div>
            <div class="map-desc">400+ Inspectors Nationwide</div>
          </div>
          <div class="right" v-else>
            <div class="map-title">We buy cars across {{ pageName }}</div>
            <div class="main">
              <div class="left">
                With over 10 years’ experience in pre-owned car,
                Quotemycars.com.au is not just a buy-back company, we're
                automotive experts and we know the market. This makes us the
                most trustworthy car buyer in
                {{ pageName }}.
                <br />
                We offer amazing prices for most of cars and we're extremely
                transparent with all our communication from every step of the
                sale.
                <br />
                Just call 1300 849 879 now to book a free valuation and sell
                your car with the smoothest selling experience.
              </div>
              <div class="right">
                <div class="item" v-for="item in pageList" :key="item.id">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="asq">
      <div class="container">
        <div class="asq-warpper">
          <div class="asq-title">Frequently Asked Questions</div>
          <div class="asq-info">
            <div
              :class="item.type ? 'asq-item click-item' : 'asq-item'"
              v-for="(item, index) in asq"
              :key="index"
            >
              <div class="question" @click="asqClick(index)">
                <img
                  src="~@/assets/images/qs.png"
                  alt=""
                  style="width: 24px; margin-right: 16px"
                />
                {{ item.question }}
              </div>
              <div class="answer" v-show="item.type">
                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-bar">
      <div class="container">
        <div class="warpper">
          <div class="title">Sell your car fast!</div>
          <div class="bar-btn" @click="backTop">
            Start Free Valuation Today!
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import fromRules from "@/utils/fromRules";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      swiperList: [
        {
          id: 1,
          path: require("@/assets/images/preview1.png"),
        },
        {
          id: 2,
          path: require("@/assets/images/preview2.png"),
        },
        {
          id: 3,
          path: require("@/assets/images/preview3.png"),
        },
        {
          id: 4,
          path: require("@/assets/images/preview4.png"),
        },
        {
          id: 5,
          path: require("@/assets/images/preview5.png"),
        },
        {
          id: 6,
          path: require("@/assets/images/preview6.png"),
        },
        {
          id: 7,
          path: require("@/assets/images/preview7.png"),
        },
        {
          id: 8,
          path: require("@/assets/images/preview8.png"),
        },
      ],
      swiperOptions: {
        loop: true,
        autoplay: true,
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        noSwiping: true,
        noSwipingClass: "stop-swiping",
      },
      asq: [
        {
          question: "How much I can get for my car?",
          type: false,
          answer:
            "We will contact you through the phone and discuss the condition of your car. After we reach an agreement for the price, an onsite inspection will be arranged. After the condition of the car is confirmed, we will pay you the agreed price. If the condition of the car is not as described, we will contact you for further discussion.",
        },
        {
          question: "How do I get the payment when I sell my car?",
          type: false,
          answer:
            "We will pay the agreed price directly into your bank account by Osko, which means you receive funds will happen in almost an instant, 24/7 - including weekends and public holidays. These near real-time payments automatically use Osko by BPAY.®",
        },
        {
          question: "What documents do I need to sell my car?",
          type: false,
          answer:
            "To make sure you are the actual owner of the vehicle, following documents are required when you sell the car: Photo ID (Driver’s License recommended), Registration papers. If you are selling the vehicle for anyone else, more documents are required: An authorization letter from the actual owner with signature, date and bank details; Photo ID of the registered owner (driver’s license recommended). ",
        },
        {
          question:
            "What should I do if my vehicle is still with outstanding finance?",
          type: false,
          answer:
            "You can still sell your car to us if the car is still having finance on it. We will help you with the payout process. To do this, we will need an up-to-date payout letter directly issued by your financial institution.",
        },
        {
          question: "When and how do you collect the car?",
          type: false,
          answer:
            "We will collect the car on the selected date chosen by you and us together. A toll truck or an authorized driver will come to collect the vehicle. Please remember to provide all sets of keys and all books (such as service logbook, user manual) to the driver.",
        },
        {
          question:
            "Do I need to provide Roadworthy Certificate or pink slip when I sell the car?",
          type: false,
          answer:
            "No, as we are a registered licensed motor dealer. No pink slip or RWC needed when you sell the car to us.",
        },
      ],
      navList: [
        {
          name: "Australian Capital Territory",
          id: 1,
          children: [
            {
              id: 1,
              name: "Canberra",
            },
            {
              id: 2,
              name: "Barton",
            },
            {
              id: 3,
              name: "Yarralumla",
            },
            {
              id: 4,
              name: "Forrest",
            },
            {
              id: 5,
              name: "Deakin",
            },
            {
              id: 6,
              name: "Red Hill",
            },
            {
              id: 7,
              name: "Hughes",
            },
            {
              id: 8,
              name: "Kambah",
            },
            {
              id: 9,
              name: "Binalong",
            },
            {
              id: 10,
              name: "Sutton",
            },
            {
              id: 11,
              name: "Bookham",
            },
            {
              id: 12,
              name: "Lyons",
            },
            {
              id: 13,
              name: "Bowning",
            },
            {
              id: 14,
              name: "Murrumbateman",
            },
            {
              id: 15,
              name: "Hall",
            },
          ],
        },
        {
          name: "New South Wales",
          id: 2,
          children: [
            {
              id: 1,
              name: "Albury",
            },
            {
              id: 2,
              name: "Armidale",
            },
            {
              id: 3,
              name: "Central Coast",
            },
            {
              id: 4,
              name: "Coffs Harbour",
            },
            {
              id: 5,
              name: "Dubbo",
            },
            {
              id: 6,
              name: "Lismore",
            },
            {
              id: 7,
              name: "Maitland",
            },
            {
              id: 8,
              name: "Orange",
            },
            {
              id: 9,
              name: "Newcastle",
            },
            {
              id: 10,
              name: "Port Macquarie",
            },
            {
              id: 11,
              name: "Sydney",
            },
            {
              id: 12,
              name: "Tamworth",
            },
            {
              id: 13,
              name: "Wagga Wagga",
            },
            {
              id: 14,
              name: "Wollongong",
            },
          ],
        },
        {
          name: "Northern Territory",
          id: 3,
          children: [
            {
              id: 1,
              name: "Alice Springs",
            },
            {
              id: 2,
              name: "Darwin",
            },
            {
              id: 3,
              name: "Galiwinku",
            },
            {
              id: 4,
              name: "Jabiru",
            },
            {
              id: 5,
              name: "Katherine",
            },
            {
              id: 6,
              name: "Litchfield",
            },
            {
              id: 7,
              name: "Maningrida",
            },
            {
              id: 8,
              name: "Nguiu",
            },
            {
              id: 9,
              name: "Nhulunbuy",
            },
            {
              id: 10,
              name: "Palmerston-East Arm",
            },
            {
              id: 11,
              name: "Port Keats",
            },
            {
              id: 12,
              name: "Tennant Creek",
            },
            {
              id: 13,
              name: "Wadeye/Victoria-Daly",
            },
            {
              id: 14,
              name: "Yulara",
            },
          ],
        },
        {
          name: "Queensland",
          id: 4,
          children: [
            {
              id: 1,
              name: "Beenleigh",
            },
            {
              id: 2,
              name: "Brisbane",
            },
            {
              id: 3,
              name: "Cairns",
            },
            {
              id: 4,
              name: "Gold Coast",
            },
            {
              id: 5,
              name: "Ipswich",
            },
            {
              id: 6,
              name: "Mackay",
            },
            {
              id: 7,
              name: "Rockhampton",
            },
            {
              id: 8,
              name: "Sunshine Coast",
            },
            {
              id: 9,
              name: "Toowoomba",
            },
            {
              id: 10,
              name: "Townsville",
            },
            {
              id: 11,
              name: "Bundaberg",
            },
            {
              id: 12,
              name: "Gladstone",
            },
            {
              id: 13,
              name: "Emerald",
            },
            {
              id: 14,
              name: "Mount Isa",
            },
          ],
        },
        {
          name: "South Australia",
          id: 5,
          children: [
            {
              id: 1,
              name: "Adelaide",
            },
            {
              id: 2,
              name: "Gawler",
            },
            {
              id: 3,
              name: "Goolwa",
            },
            {
              id: 4,
              name: "Millicent",
            },
            {
              id: 5,
              name: "Mount Barker",
            },
            {
              id: 6,
              name: "Mount Gambier",
            },
            {
              id: 7,
              name: "Murray Bridge",
            },
            {
              id: 8,
              name: "Nairne",
            },
            {
              id: 9,
              name: "Nuriootpa",
            },
            {
              id: 10,
              name: "Port Augusta",
            },
            {
              id: 11,
              name: "Port Lincoln",
            },
            {
              id: 12,
              name: "Port Pirie",
            },
            {
              id: 13,
              name: "Strathalbyn",
            },
            {
              id: 14,
              name: "Victor Harbor",
            },
            {
              id: 15,
              name: "Whyalla",
            },
          ],
        },
        {
          name: "Tasmania",
          id: 6,
          children: [
            {
              id: 1,
              name: "Blackmans Bay",
            },
            {
              id: 2,
              name: "Bridgewater",
            },
            {
              id: 3,
              name: "Burnie",
            },
            {
              id: 4,
              name: "Devonport",
            },
            {
              id: 5,
              name: "Gagebrook",
            },
            {
              id: 6,
              name: "George Town",
            },
            {
              id: 7,
              name: "Hobart",
            },
            {
              id: 8,
              name: "Kingston",
            },
            {
              id: 9,
              name: "Launceston",
            },
            {
              id: 10,
              name: "Longford",
            },
            {
              id: 11,
              name: "New Norfolk",
            },
            {
              id: 12,
              name: "Smithton",
            },
            {
              id: 13,
              name: "Somerset",
            },
            {
              id: 14,
              name: "Ulverstone",
            },
            {
              id: 15,
              name: "Wynyard",
            },
          ],
        },
        {
          name: "Victoria",
          id: 7,
          children: [
            {
              id: 1,
              name: "Ballarat",
            },
            {
              id: 2,
              name: "Bendigo",
            },
            {
              id: 3,
              name: "Geelong",
            },
            {
              id: 4,
              name: "Melbourne",
            },
            {
              id: 5,
              name: "Melton",
            },
            {
              id: 6,
              name: "Mildura",
            },
            {
              id: 7,
              name: "Ocean Grove",
            },
            {
              id: 8,
              name: "Pakenham",
            },
            {
              id: 9,
              name: "Shepparton",
            },
            {
              id: 10,
              name: "Sunbury",
            },
            {
              id: 11,
              name: "Torquay",
            },
            {
              id: 12,
              name: "Traralgon",
            },
            {
              id: 13,
              name: "Wangaratta",
            },
            {
              id: 14,
              name: "Warrnambool",
            },
            {
              id: 15,
              name: "Wodonga",
            },
          ],
        },
        {
          name: "Western Australia",
          id: 8,
          children: [
            {
              id: 1,
              name: "Albany",
            },
            {
              id: 2,
              name: "Broome",
            },
            {
              id: 3,
              name: "Bunbury",
            },
            {
              id: 4,
              name: "Busselton",
            },
            {
              id: 5,
              name: "Collie",
            },
            {
              id: 6,
              name: "Dunsborough",
            },
            {
              id: 7,
              name: "Esperance",
            },
            {
              id: 8,
              name: "Geraldton",
            },
            {
              id: 9,
              name: "Kalgoorlie-Boulder",
            },
            {
              id: 10,
              name: "Karratha",
            },
            {
              id: 11,
              name: "Margaret River",
            },
            {
              id: 12,
              name: "Northan",
            },
            {
              id: 13,
              name: "Perth",
            },
            {
              id: 14,
              name: "Port Hedland",
            },
            {
              id: 15,
              name: "Yanchep",
            },
          ],
        },
      ],
      pageId: "",
      cId: "",
      ruleForm: {
        kilometres: "",
        year: "",
      },
      rules: {
        kilometres: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
        year: [
          {
            required: true,
            validator: fromRules.validateNumber,
            message: "Please enter the correct information",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    pageName() {
      if (this.pageId) {
        return this.navList.find((item) => item.id == this.pageId).name;
      } else {
        return "";
      }
    },
    cPageName() {
      if (this.pageId) {
        return this.navList
          .find((item) => item.id == this.pageId)
          .children.find((item) => item.id == this.cId).name;
      } else {
        return "";
      }
    },
    pageList() {
      if (this.pageId || this.cId) {
        return this.navList.find((item) => item.id == this.pageId).children;
      } else {
        return [];
      }
    },
  },
  methods: {
    // asq点击事件
    asqClick(index) {
      this.asq[index].type = !this.asq[index].type;
    },
    backTop() {
      window.scrollTo(0, 0);
    },
    handleContinue() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.ruleForm.kilometres >= 150000 || this.ruleForm.year < 2012) {
            this.$alert(
              "*No older than 2012 with less than 150,000kms",
              "Tips",
              {
                confirmButtonText: "Confirm",
                confirmButtonClass: "confirm-button",
                customClass: "my-message",
              }
            );
          } else if (this.ruleForm.year > new Date().getFullYear()) {
            this.$alert(
              `*No more than ${new Date().getFullYear()} with less than 150,000kms`,
              "Tips",
              {
                confirmButtonText: "Confirm",
                confirmButtonClass: "confirm-button",
                customClass: "my-message",
              }
            );
          } else {
            sessionStorage.setItem("startInfo", JSON.stringify(this.ruleForm));
            this.$router.push("/start");
          }
        }
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      this.pageId = this.$route.query.id;
      this.cId = this.$route.query.cId;
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        this.pageId = val.query.id;
        this.cId = val.query.cId;
      },
    },
  },
};
</script>

<style lang="less">
.confirm-button {
  background-color: #f5c03e !important;
  border-color: #f5c03e !important;
  // font-weight: b;
  font-family: Poppins-SemiBold !important;
}
.my-message {
  width: auto !important;
  font-family: Poppins-SemiBold !important;
}
</style>
<style scoped lang="less">
.locations {
  width: 100%;
  position: relative;
  padding-top: 120px;
  .banner {
    width: 100%;
    background-image: url("~@/assets/images/BgYellow_1.png");
    background-size: cover;
    .warpper {
      padding: 120px 0;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .left {
        font-family: Poppins-Black;
        width: 65%;
        .title {
          font-family: Poppins-Black;
          color: #222222;
          font-size: 40px;
          line-height: 47px;
          font-weight: 900;
          margin-bottom: 40px;
          margin-top: 45px;
        }
        .larger {
          font-family: Poppins-Regular;
          color: #fff;
          font-size: 24px;
          line-height: 28px;
          font-weight: 400;
          margin-bottom: 80px;
        }
        .icons {
          width: 100%;
          display: flex;
          .item {
            font-family: Poppins-ExtraBold;
            width: 30%;
            color: #222;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            font-size: 16px;
            line-height: 24px;
            margin-right: 20px;
            font-weight: 800;
            .icons-left {
              width: 71px;
              height: 71px;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.6);
              margin-right: 10px;
              img {
                width: 100%;
                transform: translate(-30%, 40%);
              }
            }
          }
        }
      }
      .right {
        width: 33%;
        background: #fff;
        border-radius: 10px;
        padding: 15px 40px 40px 40px;
        box-sizing: border-box;
        .lable {
          width: 136px;
          height: 136px;
          // transform: translate(-50%, -50%);
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          font-family: Poppins-ExtraBold;
          font-size: 24px;
          line-height: 28px;
          font-weight: 800;
          text-align: center;
          margin-bottom: 8px;
          color: #212121;
        }
        .desc {
          color: #212121;
          font-family: Poppins-Regular;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          margin-bottom: 48px;
        }
        .form {
          width: 100%;
          font-family: Poppins-Regular;
          .form-item {
            width: 100%;
            margin-bottom: 16px;
            /deep/ .el-input__inner {
              -web-kit-appearance: none;
              -moz-appearance: none;
              outline: 0;
              width: 100%;
              padding: 20px;
              background: #f5f5f5;
              border: 1px solid transparent;
              color: #212121;
              border-radius: 4px;
              font-family: Poppins-Regular;
              &:focus {
                border: 1px solid #f5c03e;
              }
            }
          }
          .subimit {
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            background: #f5c03e;
            text-align: center;
            font-family: Poppins-SemiBold;
            font-weight: 600;
            font-size: 14px;
            border-radius: 6px;
            line-height: 16px;
            margin-top: 48px;
            user-select: none;
            cursor: pointer;
            transition: all 0.3s;
            color: #404040;
            &:hover {
              transform: translateY(-5px);
              box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
  .row-bg {
    width: 100%;
    background: #3b3b3b;
    .warpper {
      padding: 120px 0;
      box-sizing: border-box;
      .title {
        color: #fff;
        font-family: Poppins-Black;
        font-size: 40px;
        line-height: 47px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 24px;
      }
      .bg-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        // align-items: center;
        .item {
          padding: 20px 13px;
          box-sizing: border-box;
          width: 25%;
          .top {
            width: 146px;
            height: 146px;
            margin: 0 auto;
            margin-bottom: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-title {
            color: #fff;
            font-family: Poppins-SemiBold;
            font-size: 24px;
            line-height: 28px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 12px;
          }
          .item-desc {
            color: #fff;
            font-family: Poppins-Regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            text-align: center;
          }
        }
      }
      .bg-btn {
        width: 395px;
        padding: 14px 24px;
        margin: 0 auto;
        box-sizing: border-box;
        background: #f5c03e;
        text-align: center;
        font-family: Poppins-SemiBold;
        font-weight: 600;
        border-radius: 6px;
        font-size: 14px;
        line-height: 16px;
        margin-top: 48px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s;
        color: #404040;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .map {
    width: 100%;
    background: url("~@/assets/images/BgYellow.png");
    background-size: cover;
    padding: 60px 0;
    box-sizing: border-box;
    .warpper {
      display: flex;
      align-items: center;
      .wpb_wrapper {
        box-sizing: border-box;
        width: 35%;
        .map-info {
          margin: 0 auto;
          img {
            width: 291px;
          }
        }
        // svg.australia_map {
        //   max-width: 100%;
        //   height: auto;
        // }

        // svg.australia_map a:hover > * {
        //   fill: #faff00;
        // }
      }
      .right {
        width: 65%;
        .map-title {
          font-family: Poppins-Black;
          color: #303030;
          font-size: 40px;
          font-weight: 900;
          line-height: 47px;
          text-align: center;
          margin-bottom: 24px;
        }
        .map-desc {
          font-family: Poppins-Regular;
          color: #3b3b3b;
          font-size: 30px;
          font-weight: 400;
          line-height: 30px;
          text-align: center;
        }
        .main {
          display: flex;
          justify-content: space-between;
          .left {
            width: 400px;
            // padding: 10px;
            font-family: Poppins-Regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.6);
          }
          .right {
            width: 320px;
            display: flex;
            flex-wrap: wrap;
            // align-content: flex-end;
            .item {
              width: 42%;
              font-family: Poppins-SemiBold;
              font-size: 16px;
              font-weight: 600;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.65);
              margin-bottom: 5px;
              position: relative;
              margin-right: 12px;
              &::before {
                content: "";
                display: block;
                width: 4px;
                height: 4px;
                background: #393939;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -16px;
              }
            }
          }
        }
      }
    }
  }
  .asq {
    width: 100%;
    background: #fff;
    .asq-warpper {
      padding: 120px 0 24px 0;
      box-sizing: border-box;
      .asq-title {
        color: #212121;
        font-family: Poppins-Black;
        font-size: 40px;
        line-height: 47px;
        font-weight: 900;
        text-align: center;
        margin-bottom: 24px;
      }
      .asq-info {
        width: 822px;
        margin: 0 auto;
        .asq-item {
          width: 100%;
          margin-bottom: 15px;
          .question {
            background: #f6f6f6;
            padding: 14px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-family: Poppins-SemiBold;
            color: rgba(0, 0, 0, 0.65);
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            cursor: pointer;
            transition: all 0.5s;
            .icon-plus-sign {
              margin-right: 16px;
            }
            .icon-minus-sign {
              margin-right: 16px;
            }
          }
          .answer {
            height: auto;
            padding: 10px 14px;
            box-sizing: border-box;
            color: rgba(0, 0, 0, 0.6);
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            font-family: Poppins-Regular;
            background: #fff;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
            transition: all 1s;
          }
        }
        .click-item {
          .question {
            background: #f5c03e;
            color: rgba(57, 57, 57, 1);
          }
        }
      }
    }
  }
  .bottom-bar {
    width: 100%;
    height: 177px;
    background: url("~@/assets/images/bar.png") rgba(0, 0, 0, 0.8);
    background-size: cover;
    background-blend-mode: multiply;
    .warpper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        color: #fff;
        font-family: Poppins-Black;
        font-size: 40px;
        font-weight: 900;
        line-height: 47px;
        margin-right: 30px;
      }
      .bar-btn {
        // width: 320px;
        padding: 14px 24px;
        box-sizing: border-box;
        background: #f5c03e;
        text-align: center;
        font-family: Poppins-SemiBold;
        font-weight: 600;
        font-size: 14px;
        border-radius: 6px;
        line-height: 16px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s;
        color: #404040;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0 20px 38px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}
@media only screen and (max-width: 690px) {
  .locations {
    padding-top: 64px;
    .banner {
      width: 100%;
      .warpper {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        .left {
          width: 100%;
          margin-bottom: 25px;
          .title {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 20px;
            margin-top: 0;
          }
          .larger {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
          }
          .icons {
            // flex-direction: column;
            .item {
              width: 100%;
              font-size: 16px;
              line-height: 16px;
              margin-right: 0;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .icons-left {
                width: 40px;
                height: 40px;
                margin-bottom: 24px;
                margin-left: 16px;
              }
              .info {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
        .right {
          width: 100%;
          padding: 0 20px 20px 20px;
          .lable {
            width: 60px;
            height: 60px;
          }
          .title {
            font-size: 16px;
            line-height: 19px;
          }
          .desc {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 24px;
          }
          .form {
            .subimit {
              margin-top: 16px;
            }
          }
        }
      }
    }
    .row-bg {
      .warpper {
        padding: 20px 0;
        .title {
          font-size: 24px;
          line-height: 28px;
        }
        .bg-info {
          flex-direction: column;
          .item {
            width: 100%;
            .item-title {
              font-size: 16px;
              line-height: 19px;
            }
            .item-desc {
              font-size: 12px;
              left: 14px;
            }
          }
        }
        .bg-btn {
          width: 100%;
        }
      }
    }
    .map {
      padding: 20px 0;
      box-sizing: border-box;
      .warpper {
        flex-direction: column-reverse;
        .wpb_wrapper {
          width: 100%;
          .map-info {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .right {
          width: 100%;
          margin-bottom: 30px;
          .map-title {
            font-size: 24px;
            line-height: 28px;
          }
          .map-desc {
            font-size: 21px;
            line-height: 21px;
          }
          .main {
            flex-direction: column;
            .left {
              width: 100%;
              margin-bottom: 24px;
              font-size: 16px;
              line-height: 20px;
            }
            .right {
              width: 100%;
              font-size: 16px;
              .item {
                width: 50%;
                text-align: center;
                margin-right: 0;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .asq {
      .asq-warpper {
        padding: 20px 0;
        .asq-title {
          font-size: 24px;
          line-height: 28px;
        }
        .asq-info {
          width: 100%;
          .asq-item {
            .question {
              font-size: 12px;
              line-height: 14px;
            }
            .answer {
              padding: 10px 14px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }
    .bottom-bar {
      background: #f5c03e;
      .warpper {
        padding: 20px 0;
        box-sizing: border-box;
        flex-direction: column;
        .title {
          font-size: 24px;
          line-height: 28px;
          margin: 0 auto;
          margin-bottom: 24px;
        }
        .bar-btn {
          width: 100%;
          background: #303030;
          color: #fff;
        }
      }
    }
  }
}
@media only screen and (min-width: 690px) and (max-width: 999px) {
  .locations {
    padding-top: 64px;
    .banner {
      width: 100%;
      .warpper {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        .left {
          width: 100%;
          margin-bottom: 25px;
          .title {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 20px;
            margin-top: 0;
          }
          .larger {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
          }
          .icons {
            // flex-direction: column;
            .item {
              width: 100%;
              font-size: 16px;
              line-height: 16px;
              margin-right: 0;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              .icons-left {
                width: 40px;
                height: 40px;
                margin-bottom: 24px;
                margin-left: 16px;
              }
              .info {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }
        .right {
          width: 100%;
          padding: 0 20px 20px 20px;
          .lable {
            width: 60px;
            height: 60px;
          }
          .title {
            font-size: 16px;
            line-height: 19px;
          }
          .desc {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 24px;
          }
          .form {
            .subimit {
              margin-top: 16px;
            }
          }
        }
      }
    }
    .row-bg {
      .warpper {
        padding: 20px 0;
        .title {
          font-size: 24px;
          line-height: 28px;
        }
        .bg-info {
          flex-direction: column;
          .item {
            width: 100%;
            .item-title {
              font-size: 16px;
              line-height: 19px;
            }
            .item-desc {
              font-size: 12px;
              left: 14px;
            }
          }
        }
        .bg-btn {
          width: 100%;
        }
      }
    }
    .map {
      padding: 20px 0;
      box-sizing: border-box;
      .warpper {
        flex-direction: column-reverse;
        .wpb_wrapper {
          width: 100%;
          .map-info {
            width: 100%;
            img {
              width: 100%;
            }
          }
        }
        .right {
          width: 100%;
          margin-bottom: 30px;
          .map-title {
            font-size: 24px;
            line-height: 28px;
          }
          .map-desc {
            font-size: 21px;
            line-height: 21px;
          }
          .main {
            flex-direction: column;
            .left {
              width: 100%;
              margin-bottom: 24px;
              font-size: 16px;
              line-height: 20px;
            }
            .right {
              width: 100%;
              font-size: 16px;
              .item {
                width: 50%;
                text-align: center;
                margin-right: 0;
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .asq {
      .asq-warpper {
        padding: 20px 0;
        .asq-title {
          font-size: 24px;
          line-height: 28px;
        }
        .asq-info {
          width: 100%;
          .asq-item {
            .question {
              font-size: 12px;
              line-height: 14px;
            }
            .answer {
              padding: 10px 14px;
              font-size: 12px;
              line-height: 14px;
            }
          }
        }
      }
    }
    .bottom-bar {
      background: #f5c03e;
      .warpper {
        padding: 20px 0;
        box-sizing: border-box;
        flex-direction: column;
        .title {
          font-size: 24px;
          line-height: 28px;
          margin: 0 auto;
          margin-bottom: 24px;
        }
        .bar-btn {
          width: 100%;
          background: #303030;
          color: #fff;
        }
      }
    }
  }
}
</style>
